@tailwind components;
@tailwind utilities;

.MuiFab-info,
.MuiButton-containedPrimary {
  background: #157cfc !important;
}

.MuiButton-containedSecondary {
  background: #79bc76 !important;
}

input {
  background-color: transparent !important;
  border-color: transparent !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-tile {
  border-radius: 10px;
  cursor: pointer;
}

.image-tile:hover {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.chip {
  cursor: pointer;
}

.chip:hover {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.piece-image {
  width: 80%;
  border-radius: 10px;
  cursor: pointer;
}

.piece-image:hover {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.actions {
  color: #545454 !important;
  cursor: pointer;
}

.actions:hover {
  color: #171717 !important;
}

.shadow {
  -webkit-box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.12);
  -moz-box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.12);
  box-shadow: 4px 4px 17px 0px rgba(34, 60, 80, 0.12);

  width: 50px;
  height: 50px !important;
}

.shadow:hover {
  -webkit-box-shadow: 4px 4px 19px 1px rgba(34, 60, 80, 0.05);
  -moz-box-shadow: 4px 4px 19px 1px rgba(34, 60, 80, 0.05);
  box-shadow: 4px 4px 19px 1px rgba(34, 60, 80, 0.05);
}

.dropdown-shadow {
  box-shadow: 0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1),
    0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);
}

.sort {
  color: gray;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.fab {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}


 .nav-for-slider .swiper-slide {
        cursor: pointer;
        margin-right: 0 !important;
      }
      .swiper-wrapper {
        height: auto;
      }
      .nav-for-slider .swiper-slide img {
        border: 2px solid transparent;
        border-radius: 28px;
      }
      .nav-for-slider .swiper-slide-thumb-active img {
        border-color: rgb(79 70 229);
      }
      
